import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Contact from "../components/contact"
import ContactCategoriesQuery from "../queries/contactCategories"

const ContactPage = ({ data }) => {
  const contactCategories = ContactCategoriesQuery()

  return (
    <Layout>
      <Seo title="kontakt" />
      <section className="article__content">
        <h1 className="article__heading">Kontakt</h1>
      </section>
      {Object.keys(contactCategories).map((category, categoryIndex) => (
        <div key={`category-${categoryIndex}`}>
          <section className="article__content contacts">
            <h2 className="contacts__heading">{category}</h2>
            {contactCategories[category].map((contact, contactIndex) => (
              <Contact
                name={contact.name}
                description={contact.description}
                phone={contact.phone}
                mobile={contact.mobile}
                key={`category-${categoryIndex}-contact-${contactIndex}`}
                contactId={contact.contactId}
              />
            ))}
          </section>
        </div>
      ))}
    </Layout>
  )
}

export default ContactPage
